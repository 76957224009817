import { TextField } from "@mui/material"
import { useEffect, useState } from "react"

export const ColorsComponent = (props) => {
    const [primary, setPrimary] = useState('')
    const [secondary, setSecondary] = useState('')
    useEffect(()=>{
        props.onColorsChange({primary, secondary})
    },[primary,secondary])
    return (
        <div style={{ marginBottom: '10px' }}>
            <div style={{ margin: '10px' }}>Colors</div>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ margin: '10px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ width: '90%', height: '2px', backgroundColor: `${primary}`, borderRadius: '200px' }} />
                    <TextField onChange={(e)=>setPrimary(e.target.value)} style={{ margin: '10px' }} label="Primary" />
                </div>
                <div style={{ margin: '10px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ width: '100%', height: '2px', backgroundColor: `${secondary}`, borderRadius: '200px' }} />
                    <TextField onChange={(e)=>setSecondary(e.target.value)} style={{ margin: '10px' }} label="Secondary" />
                </div>
            </div>

        </div>
    )
}