import { Alert, Button, TextField } from "@mui/material"
import { useState } from "react"
import { checkPollIdExists, insertPoll, setDocumentHandler } from "../../helpers/helpers"
import { ColorsComponent } from "../colors-component/colors-component"

export const CreatePoll = (props) => {
    const [pollId, setPollId] = useState(null)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showMessage, setShowMessage] = useState({ type: '', message: '', show: false })
    const [colors, setColors] = useState({ primary: '#920044', secondary: '#dd77a6' })
    function createNewPoll() {
        if (pollId == null || pollId == '') {
            alert('please enter a poll id')
            return
        }
        checkPollIdExists(pollId).then((res) => {
            if (res == 1) {
                setShowMessage({ type: 'error', message: 'poll id already exists', show: true })
            }
            else {
                insertPoll(pollId, colors).then(() => {
                    setShowMessage({ type: 'success', message: 'Poll created successfully', show: true })
                    // alert('inserted poll successfully')
                    setTimeout(() => {
                        setShowMessage({ type: '', message: '', show: false })
                    }, 3000);
                })
            }
        })
    } return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <div >Create new poll</div>
        <TextField onChange={(e) => setPollId(e.target.value)} style={{ margin: '20px' }} label="New poll id" />
        <ColorsComponent onColorsChange={(colorsObject) => setColors(colorsObject)} />
        <Button onClick={createNewPoll} variant="outlined">Create new poll</Button>
        {true && <Alert style={{margin:'20px'}} severity={showMessage.type}>{showMessage.message}</Alert>}

    </div >)
}