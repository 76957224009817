import { useEffect, useState } from "react"
import { poll_id } from "../../helpers/helpers"
let firstTime = true;
export const UserSide = (props) => {
    const [id, setId] = useState(poll_id)
    useEffect(() => {
        setId(poll_id)
    }, [poll_id])
    useEffect(() => {
        if ((poll_id == null || poll_id == '' || poll_id == 'undefined' || poll_id == undefined) && firstTime) {
            // alert('please enter a poll id')
            firstTime = false;
        }
        setTimeout(() => {
            firstTime = true
        }, 2000);

    }, [poll_id])
    return (<div>
        {poll_id ? <iframe style={{ borderStyle: 'none', margin: '50px' }} height="580" src={`https://polls.cloudvie.live/?id=${id}`} />:<div>Please enter a poll id</div>}
    </div>)
}