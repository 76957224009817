import { Button, TextField } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { getDocument, poll_id, setDocumentHandler } from "../../helpers/helpers"
let firstTime = true;
export const Colors = () => {
    const [colors, setColors] = useState({ primary: '', secondary: '' })
    async function updateColors() {
        let doc = await getDocument(poll_id)
        doc.colors = colors;
        setDocumentHandler(doc)
        
    }
    useEffect(() => {
        if ((poll_id == undefined || poll_id == '' || poll_id == null || poll_id == 'undefined') && firstTime == true) {
        //    alert('please select a poll id to update colors')
            firstTime = false
        }
        setTimeout(() => {
            firstTime = true
        }, 1000);
    }, [])
    useEffect(() => {
        async function getColors() {
            getDocument('colors').then((res) => {
                setColors(res.colors)
            })
        }
        getColors()

    }, [poll_id])
    return poll_id ? (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <div>Colors</div>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ margin: '20px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ margin: '20px', width: '90%', height: '2px', backgroundColor: `${colors.primary}`, borderRadius: '200px' }} />
                    <TextField value={colors.primary} onChange={((e) => setColors({ primary: e.target.value, secondary: colors.secondary }))} label="primary" />
                </div>
                <div style={{ margin: '20px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ margin: '20px', width: '100%', height: '2px', backgroundColor: `${colors.secondary}`, borderRadius: '200px' }} />
                    <TextField value={colors.secondary} onChange={((e) => setColors({ primary: colors.primary, secondary: e.target.value }))} label="secondary" />
                </div>


            </div>
            <Button variant="contained" onClick={() => updateColors()} >Update colors</Button>
        </div>

    ) : (<div>Please enter a poll id</div>)
}