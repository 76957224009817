import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { getDocument, poll_id } from "../../helpers/helpers";
import _ from "lodash";
let firstTime = true;
export const Answers = (props) => {
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [responsesCount, setResponsesCount] = useState(0);
  const [answers, setAnswers] = useState(0);
  //   console.log(users);
  console.log(answers);
  useEffect(() => {
    if (
      (poll_id == null ||
        poll_id == "" ||
        poll_id == "undefined" ||
        poll_id == "undefined") &&
      firstTime == true
    ) {
      //  alert('please select a poll id to show answers')
      firstTime = false;
    }
    setTimeout(() => {
      firstTime = true;
    }, 1000);
  }, []);

  useEffect(() => {
    let ans = users?.flatMap((user) => {
      let a = user?.answers?.map((item) => {
        item.username = user?.username;
        item.email = user?.email;
        return { ...item };
      });
      return a;
    });
    ans = _.orderBy(ans, ["question"], ["asc"]);
    setAnswers(ans);
  }, [users]);
  useEffect(() => {
    getDocument()
      .then((res) => {
        setUsers(res.users);
        setUsersCount(res.users.length);
      })
      .then(() => {
        let result = 0;
        for (let i = 0; i < users.length; i++) {
          result = users[i].answers.length + result;
        }
        setResponsesCount(result);
      });
  }, [poll_id, users]);
  return poll_id ? (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1>
        {usersCount} users responded {responsesCount} responses to this poll
      </h1>

      {answers &&
        answers.map((answer, index) => {
          return (
            <div key={index}>
              <div
                style={{
                  width: "400px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
                key={index}
              >
                <Divider sx={{ margin: "10px", width: "100%" }} color="black" />
                <div>
                  <b>Question :</b> {answer.question}
                </div>
                <div>
                  <b>Chosen option : </b>
                  {answer.option}
                </div>
                <div>
                  <b>Time : </b>
                  {new Date(answer.time).toDateString()} -{" "}
                  {new Date(answer.time).getHours().toString()}H:
                  {new Date(answer.time).getMinutes().toString()}M
                </div>
                <div>
                  {" "}
                  <b>Username : </b>
                  {answer.username ? answer.username : "not available"}
                </div>
                <div>
                  {" "}
                  <b>E-mail : </b>
                  {answer.email ? answer.email : "not available"} <b />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  ) : (
    <div>Please enter a poll id</div>
  );
};
