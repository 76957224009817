import { Button, Dialog, Icon, Modal, Popover, Typography } from "@mui/material";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase/firebase";
import { deleteQuestion, getDocument, poll_id, setDocumentHandler } from "../helpers/helpers";
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from "@mui/system";
let firstTime = true
const Questions = (props) => {
    const [currentQuestion, setCurrentQuestion] = useState('firstTime')
    const [showModal, setShowModal] = useState(false)
    const [questionToDelete, setQuestionToDelete] = useState(null)
    useEffect(() => {
        if ((poll_id == null || poll_id == '' || poll_id == 'undefined') && firstTime == true) {
            //    alert('please select a poll id to show questions')
            firstTime = false
        }
        setTimeout(() => {
            firstTime = true
        }, 1000);
    }, [])
    const { questions, onAnswer } = props;


    async function currentQuestionHandler(document) { // "activated" to indiacted whethere poll is activated or not
        let pollDocument = await getDocument()
        pollDocument.current = document
        setDocumentHandler(pollDocument)
    }
    async function activateQuestion(question) {
        let document = await getDocument(); // gets all questions and current question
        let tempQuestion = document.questions.find((q) => q.question === question.question) // get index of chosen question to activate
        let index = document.questions.indexOf(tempQuestion);
        document.questions[index].status = "1"; // set status to 1
        document.questions[index].active = true // set active to true
        setDocumentHandler(document)
        currentQuestionHandler(document.questions[index]) // set current question to activated question
    }
    async function archive(question) {
        let document = await getDocument(); // gets all questions and current question
        let tempQuestion = document.questions.find((q) => q.question === question.question) // get index of chosen question to activate
        let index = document.questions.indexOf(tempQuestion);
        tempQuestion.status = "2"
        console.log(tempQuestion);
        document.questions[index] = tempQuestion
        document.questions[index].status = "2"; // set status to 1
        document.questions[index].options = document.current.options
        document.current = null
        console.log(document);
        setDocumentHandler(document)

    }
    async function disableQuestion(question) {
        let document = await getDocument(); // gets all questions and current question
        let tempQuestion = document.questions.find((q) => q.question === question.question) // get index of chosen question to activate
        let index = document.questions.indexOf(tempQuestion);
        document.questions[index].status = "3"; // set status to 3
        document.questions[index].active = false // set active to true
        setDocumentHandler(document)
        currentQuestionHandler(document.questions[index]) // set current question to activated question
    }
    return poll_id ? (
        <div>
            <Modal
                open={showModal}
                onClose={() => { setShowModal(false) }}
            // aria-labelledby="modal-modal-title"
            // aria-describedby="modal-modal-description"
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20, height: '100%' }}>
                    <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', gap: 20, padding: 50, display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <div style={{ color: 'white', fontWeight: 'bold' }}>Are you sure you want to delete this question?</div>
                        </div>
                        <div style={{ gap: 20, display: 'flex' }}>
                            <Button onClick={() => {
                                console.log(questionToDelete);
                                deleteQuestion(questionToDelete.question)
                                setShowModal(false)
                            }} style={{ color: 'white', fontWeight: 'bold', backgroundColor: 'red' }}>Yes</Button>
                            <Button onClick={() => { setShowModal(false) }} style={{ color: 'white', fontWeight: 'bold', backgroundColor: 'blue' }}>No</Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <div>Questions</div>
            {questions && <div className="outerList">
                {questions?.map((question, key) => {
                    let status = ''
                    if (question.status == "2") {
                        status = "Archived"
                    }
                    return (
                        <div key={key}>
                            <div className="questionsList">
                                <div className="questionContainer">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>Question : </div>
                                    <div style={{ color: 'black' }} >{question.question}  </div>
                                    {status == 'Archived' && <div style={{ backgroundColor: 'gray', borderRadius: '5px', padding: '5px', marginLeft: '6px' }}>{status}</div>}
                                </div>

                                {question.options.map((option, index) => {
                                    return (
                                        <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <div style={{ marginLeft: '10px' }}>{index + 1} : </div>
                                            <div className="optionContainer"> {option.text} </div>
                                            <div className="votes"> votes : {option.value}</div>
                                        </div>
                                    )
                                })}


                                <div className="actionsContainer">
                                    <div>
                                        <button className="actionBtn"
                                            disabled={(question.status == 0 && (currentQuestion == question.question || currentQuestion == 'firstTime')) ? false : true}
                                            onClick={() => {
                                                setCurrentQuestion(question.question) // to prevent user from activating other questions while current question is active , until he's done with this question
                                                activateQuestion(question)
                                            }}>Activate</button>
                                    </div>
                                    <div>
                                        <button
                                            className="actionBtn"
                                            disabled={question.status == "1" ? false : true} onClick={() => {
                                                disableQuestion(question)
                                            }}>Show result</button>
                                    </div>
                                    <div>
                                        <button
                                            className="actionBtn"
                                            disabled={question.status == '3' ? false : true} onClick={() => {
                                                archive(question)
                                                setCurrentQuestion('firstTime') // enable (activate) button with all other questions as it's firstTime
                                            }}>Archive</button>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', padding: 10 }}>
                                    <div onClick={() => {
                                        setQuestionToDelete(question)
                                        setShowModal(true)
                                    }}>
                                        <DeleteIcon style={{ cursor: 'pointer', color: 'rgba(255,100,100)' }} color="red" />
                                    </div>
                                </div>

                            </div>

                        </div>
                    )
                })}
            </div>}
        </div>
    ) : <div>Please enter a poll id</div>;
}
export default Questions;