import { Button, Input, TextField } from "@mui/material"
import React, { useState, useEffect } from 'react'
import { poll_id } from "../../helpers/helpers"
let firstTime = true
export const AddQuestionForm = (props) => {
    useEffect(() => {
        if ((poll_id == null || poll_id == '' || poll_id == 'undefined' || poll_id == 'undefined') && firstTime == true) {
           // alert('please select a poll id to add questions')
            firstTime = false;
        }
        setTimeout(() => {
            firstTime = true
        }, 1000);
    }, [])
    // const [optionsInput, setOptionsInput] = useState([{ text: '', value: 0 }, { text: '', value: 0 }, { text: '', value: 0 }])
    // const [question, setQuestion] = useState(props.question)
    return poll_id ?  (
        <div>
            <form className="questionForm" onSubmit={props.submitHandler} >
                <TextField variant="outlined" label="Question" value={props.question} onChange={(e) => props.setQuestion(e.target.value)} className='question' type={"text"} />

                <div className='options'>
                    <div>
                        <Button style={{ margin: '15px' }} variant="contained" onClick={() => props.setOptionsInput([...props.optionsInput, { text: '', value: 0 }])} >Add option</Button>
                        <Button style={{ margin: '15px' }} variant="outlined" onClick={() => {
                            let temp = props.optionsInput?.filter((e) => e !== '00000')
                            temp.pop()
                            props.setOptionsInput(temp)
                        }} >Remove option</Button>
                    </div>

                    {props.optionsInput.map((option, index) => {
                        // console.log(option);
                        return (
                            <TextField
                                id="outlined-basic" label={`Option ${index + 1}`} style={{ margin: '20px' }} variant="outlined"
                                key={index} onChange={(e) => {
                                    let options = props.optionsInput
                                    let tempValue = e.target.value
                                    options[index].text = tempValue
                                    props.setOptionsInput(options)
                                }} className='option' type={"text"} />
                        )
                    })}
                </div>
                <Button variant="contained" type='submit' onClick={props.onAddQuestion}>Add question</Button>
            </form>
        </div>
    ) : (<div>Please enter a poll id</div>)
}