import { collection, deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";

export var poll_id = null;
export async function getDocument() {
    const docRef = doc(db, "polls", `${poll_id}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data()
        // console.log("Document data:", docSnap.data());
    } else {
        console.log("No such document!");
    }
}
export async function checkPollIdExists(id) {
    const docRef = doc(db, "polls", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return 1 // exists
    } else {
        return 0 // does not exist
    }
}

export function setPollID(id) {
    poll_id = id
}

export async function setDocumentHandler(object) {
    await setDoc(doc(db, "polls", `${poll_id}`), object)
}
export async function insertPoll(id, colors) {
    await setDoc(doc(db, "polls", id), { users: [], colors: colors, current: null, questions: [] })
}
export async function deleteQuestion(q) {
    let res = await getDocument()
    let questions = await res.questions.filter((question) => question.question !== q)
    console.log(questions);
    res.questions = questions
    await updateDoc(doc(db, "polls", `${poll_id}`), res)
}