import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BasicModal(props) {
    // const [open, setOpen] = React.useState(false);
    const handleOpen = () => props.setOpen(true);
    const handleClose = () => props.setOpen(false);
    React.useEffect(() => {
        // setOpen(props.open)
    }, [props, props.open])
    return (
        <div>
            <Button onClick={handleOpen}>Open modal</Button>
            <Modal
                open={props.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Are you sure you want to delete this poll ?
                    </Typography>
                    <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button onClick={()=>props.onAction(true)} sx={{ color: 'red', borderColor: 'red' }} variant='outlined'>Confirm</Button>
                        <Button onClick={()=>props.onAction(false)} sx={{ borderColor: 'green' }} variant='contained'>Cancel</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
