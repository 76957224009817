import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Autocomplete, Button, TextField } from '@mui/material';
import { poll_id, setPollID } from '../helpers/helpers';
import { collection, deleteDoc, doc, onSnapshot, query } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from './modal/modal';
import BasicModal from './modal/modal';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft(props) {
    const theme = useTheme();
    const [polls, setPolls] = useState();
    const [id, setId] = useState();
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    let navigate = useNavigate();
    let params = useParams()
    useEffect(() => {
        console.log(params);
        try {
            const q = query(collection(db, "polls"));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const docs = [];
                querySnapshot?.forEach((doc) => {
                    docs.push(doc.id);
                });

                if (docs.length > 0) {
                    setPolls(docs);
                }
                console.log(polls);

            });
            return () => {
                unsubscribe();
            }
        } catch (err) {
        }
    }, [])
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    function deletePollHandler() {
        setOpenModal(true)
    }

    async function onAction(state) {
        if (state) {
            await deleteDoc(doc(db, "polls", poll_id)).then(() => {
                // props.onSetPollID('');
                props.onSetPollId('')
                setId('')
            });
        }

        setOpenModal(false)

    }
    return (
        <Box sx={{ display: 'flex' }}>
            <BasicModal setOpen={setOpenModal} onAction={onAction} open={openModal} />
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Poll id : <b>{poll_id}</b>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    {<Autocomplete
                        onChange={(e) => {
                            try {

                                //setPollID(e.target.value)
                                let temp = e.target.value
                                setId(temp)
                                props.onSetPollId(e.target.value)
                                // props.onSetPollId(e.target.value)
                                localStorage.setItem('poll_id', id)
                            } catch (e) {

                            }
                        }}
                        onSelect={((e) => {
                            try {

                                props.onSetPollId(e.target.value)
                                localStorage.setItem('poll_id', id)
                            } catch (e) {

                            }

                        })}

                        onBlur={(e) => {
                            try {

                                setPollID(e.target.value)
                                // setPollID(e.target.value)
                                // localStorage.setItem('poll_id', e.target.value)
                                // props.onBlur(e.target.value)
                                localStorage.setItem('poll_id', id)
                                console.log(e.target.value);
                                // setPollID(id)
                                props.onSetPollId(e.target.value)
                                //  navigate("/questions")
                            } catch (e) {

                            }
                        }}
                        disabled={!polls}
                        disablePortal
                        id="combo-box-demo"
                        options={polls}
                        // style={{ width: '80%' }}
                        sx={{ width: '90%', justifyContent: 'center' }}
                        renderInput={(params) => {
                            return (<TextField value={id} {...params} label={polls ? "poll id" : 'no polls yet'} />)
                        }}
                    />}
                    {[{ title: 'Questions', path: 'questions' }, { title: 'Add question', path: 'addquestion' }, { title: 'Colors', path: 'colors' }, { title: 'Create new poll', path: 'createpoll' }, { title: 'Answers', path: 'answers' }, { title: 'User-side view', path: 'user-side' }].map((object, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton onClick={() => navigate(`${object.path}`)} >
                                <ListItemIcon>
                                    {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                                </ListItemIcon>
                                <ListItemText primary={object.title} />
                            </ListItemButton>
                        </ListItem>
                    ))}

                    {poll_id && <Button onClick={() => deletePollHandler()} variant='text' sx={{ color: 'red', marginTop: '10px' }} >Delete this poll</Button>}
                </List>
                <Divider />
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                {/* <div>{localStorage.getItem("poll_id").toString()}</div> */}
            </Main>
        </Box>
    );
}
