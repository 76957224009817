import './App.css';
import { useEffect, useState } from 'react';
import { collection, doc, getDoc, onSnapshot, query } from 'firebase/firestore';
import { db } from './firebase/firebase';
import Questions from './components/questions';
import { getDocument, poll_id, setDocumentHandler, setPollID } from './helpers/helpers';
import PermanentDrawerLeft from './material-ui/drawer';
import { AddQuestionForm } from './material-ui/form/form';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Colors } from './material-ui/colors/colors';
import { CreatePoll } from './material-ui/createpoll/createpoll';
import { Answers } from './material-ui/answers/answers';
import { UserSide } from './material-ui/user-side/userSide';

function App() {
  const [optionsInput, setOptionsInput] = useState([{ text: '', value: 0 }, { text: '', value: 0 }, { text: '', value: 0 }])
  const [question, setQuestion] = useState('')
  const [questions, setQuestions] = useState('')
  const [pollId, setId] = useState('') // do not use this globally, use instead the global one in helpers.js file
  // const [colors, setColors] = useState({ primary: 'none', secondary: 'none', options: 'none', fill: 'none', optionBackground: 'none', buttons: 'none' })
  const [colors, setColors] = useState({ primary: '#920044', secondary: '#dd77a6' })

  async function addQuestion() {
    if (poll_id == 'undefined' || poll_id == '' || poll_id == null) {
      alert('please select a poll id to add questions')
      return
    }
    let document = await getDocument();

    if (document !== undefined) { // if document exists
      if (document.questions === undefined) document.questions = []
      if (document.users == undefined) document.users = [] // not neccessary but just to make sure it exists
      setDocumentHandler({
        colors: document.colors,
        users: document.users,
        current: null,
        questions: [...document.questions, {
          question: question.toString(),
          status: "0",
          options:
            optionsInput
          ,
        }]
      })

      setQuestion('')
      setOptionsInput(null)
      setOptionsInput([null, null])
      setOptionsInput([{ text: '', value: 0 }, { text: '', value: 0 }, { text: '', value: 0 }])
    }
    else { // if document does not exist
      setDocumentHandler({
        colors: colors,
        users: [],
        current: null,
        questions: [{
          question: question.toString(),
          status: "0",
          options:
            optionsInput
        }],
      })
    }
  }
  useEffect(() => {
    try {
      const q = query(collection(db, "polls"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          if (doc.id == poll_id)
            docs.push(doc.data().questions);
        });
        setQuestions(docs[0])
      });
      return () => {
        unsubscribe();
      }
    } catch (err) {
    }
  }, [poll_id, questions])
  function submitHandler(e) { // clear input values
    e.preventDefault();
    for (let i = 0; i < e.target.length; i++) {
      e.target[i].value = ''
    }
  }

  return (
    <div className="App">
      <div className='form'>
        <PermanentDrawerLeft onSetPollId={(e) => {
          setId(e)
          setPollID(e)
          // console.log(e);
        }} />
        <div style={{ flexDirection: 'column', display: 'flex' }}>
        </div>
        <Routes >
          <Route path='/' element={<div> {pollId && <Navigate to={"/questions"} />}  Please select poll id from the slider on left</div>} />
          <Route path='/addquestion' element={<AddQuestionForm question={question} submitHandler={submitHandler} optionsInput={optionsInput} setOptionsInput={setOptionsInput} setQuestion={setQuestion} onAddQuestion={addQuestion} />} />
          <Route index path='/questions' element={<Questions questions={questions} />} />
          <Route path="/colors" element={<Colors />} />
          <Route path="/createpoll" element={<CreatePoll />} />
          <Route path="/answers" element={<Answers />} />
          <Route path="/user-side" element={<UserSide />} />
        </Routes>

      </div>
    </div>
  );
}

export default App;
